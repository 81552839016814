.App {
  text-align: center;
  height: 100vh;
}

.background-image-holder {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.background-image {
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  animation: fadeIn 1s;

  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat,no-repeat;
  background-position: center center,center center;
  background-size: cover,cover;
}

.background-overlay {
  background: rgba(0,0,0,0.55);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.terexLogo {
  margin-top: 40px;
}

/* Background image settings */
body {
  margin: 0;
  padding: 0;
  background-size: auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


.layout-container {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 120px;
  position: relative;
}